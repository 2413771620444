import { UPDATE_ALL, UPDATE_CART, UPDATE_COUPON, UPDATE_NEW_PET, UPDATE_TREATS, UPDATE_HIDE_CART } from './actions'

export const initialState = {
    cart: [],
    treats: [],
    newPet: {
        name: '',
        weight: '',
        age: '',
        'body-type': '',
        'activity-level': '',
        'allergies': [],
        'current-diet': []
    },
    user: null
}

const reducer = (state = initialState, { type, payload, trigger = true }) => {
    if (trigger) {
        const event = new Event('storage-updated-cart')
        setTimeout(() => document.dispatchEvent(event), 100)
    }
    switch (type) {
        case UPDATE_CART:
            return { ...state, cart: payload }
        case UPDATE_COUPON:
            return { ...state, coupon: payload }
        case UPDATE_NEW_PET:
            if (!payload) {
                return { ...state, newPet: initialState.newPet }
            }
            return { ...state, newPet: payload }
        case UPDATE_TREATS:
            return { ...state, treats: payload }
        case UPDATE_ALL:
            return { ...state, ...payload }
        case UPDATE_HIDE_CART:
            return { ...state, hideCart: payload }
        default:
            return state
    }
}

export default reducer
