import { createTransform } from 'redux-persist'
import { initialState } from './reducers'

/**
 * `redux-persist` transformer that reset the persisted redux state after a specific period of time.
 * @param {number} expireIn For how long the state is going to be preserved
 * @param {string} [expireKey="persistencyExpiration"] Key used by the localStorage
 */
const transformExpire = (expireIn, expireKey = 'persistencyExpiration') => {
    const storedExpiration = localStorage.getItem(expireKey)
    let expired = false
    if (storedExpiration) {
        const expiring = parseInt(storedExpiration)
        const now = new Date().getTime()
        expired = !isNaN(expiring) && now > expiring
    }
    return createTransform((inboundState) => {
        setTimeout(() => {
            const expireValue = (new Date().getTime() + (expireIn * 1000)).toString()
            localStorage.setItem(expireKey, expireValue)
        }, 0)
        return inboundState
    }, (outboundState, key) => {
        return expired ? initialState[key] : outboundState
    })
}
export default transformExpire
