import React, { Component, createRef, Fragment } from 'react'
import { connect } from 'react-redux'
import { formatMoney } from 'accounting-js'

import Coupon from '../coupon/Coupon'

import './Cart.scss'

class Cart extends Component {
    constructor (props) {
        super(props)
        this.state = {
            shipping: {
                price: 0
            },
            isMobile: false,
            isExpanded: false,
            editablePet: '',
            newName: '',
            showCouponInput: false
        }
        this.addAnotherPet = this.addAnotherPet.bind(this)
        this.expand = this.expand.bind(this)
        this.hide = this.hide.bind(this)
        this.cartElement = createRef()
    }

    componentDidMount () {
        const cartElement = this.cartElement.current
        document.addEventListener('expand-cart', this.expand, false)
        document.addEventListener('hide-cart', this.hide, false)

        document.body.onclick = e => {
            if (e.target !== cartElement && !cartElement.contains(e.target))
                this.hide()
        }

        let device = () => {
            this.setState({ isMobile: window.innerWidth <= 600 })
        };

        device();

        window.addEventListener('resize', () => {
            device();
        });
    }

    componentWillUnmount () {
        document.removeEventListener('expand-cart', this.expand)
        document.removeEventListener('hide-cart', this.hide)
        document.querySelector('body').classList.remove('popup-open')
        document.body.onclick = null
    }

    hide () {
        if (this.state.isExpanded) {
            document.querySelector('body').classList.remove('popup-open')
        }
        return this.setState({ isExpanded: false })
    }

    async expand () {
        const presistedState = JSON.parse(localStorage.getItem('persist:root'))
        const payload = Object.keys(presistedState).reduce((acc, key) => {
            return {
                ...acc,
                [key]: JSON.parse(presistedState[key])
            }
        }, {})
        await this.props.dispatch({ type: 'UPDATE_ALL', payload })
        if (this.lineItems.length || this.oneTimeTreats.length) {
            this.setState({ isExpanded: true })
            document.querySelector('body').classList.add('popup-open')
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
            })
        }
    }

    async remove (petName) {
        let payload = this.props.products.filter(item => item.pet.name !== petName)
        await this.props.dispatch({ type: 'UPDATE_CART', payload })

        payload = this.props.treats.filter(item => item.pet.name !== petName || item.type !== 'plan')
        await this.props.dispatch({ type: 'UPDATE_TREATS', payload })
        if (!this.subTotal) {
            if (window.location.href.includes('/#/checkout')) {
                window.location.href = '/meals/#/'
            }
            this.hide()
        }
    }

    async removeTreat (productId) {
        const payload = this.props.treats.filter(item => item.treat.id !== productId || item.type !== 'one-time')
        await this.props.dispatch({ type: 'UPDATE_TREATS', payload })
        if (!this.subTotal) this.hide()
    }

    changeTreatQty (productId, plus, qty) {
        let treats = this.props.treats.map(item => ({ ...item }))
        let i = treats.findIndex(({ treat, type }) => treat.id === productId && type === 'one-time')
        if (plus === null) {
            treats[i].treat.qty = parseInt(qty) || 0
        } else {
            if (plus) {
                treats[i].treat.qty++
            } else {
                treats[i].treat.qty--
                if (treats[i].treat.qty <= 0) {
                    treats.splice(i, 1)
                }
            }
        }

        const payload = treats
        this.props.dispatch({ type: 'UPDATE_TREATS', payload })
    }

    linePrice (product, prices) {
        return prices.reduce((sum, { price, size }) => sum + price * product[size], 0)
    }

    get subTotal () {
        return this.props.products.reduce((sum, { product, prices }) => {
            return sum + this.linePrice(product, prices)
        }, 0) + this.props.treats.reduce((sum, { treat: { price, qty } }) => sum + price * qty, 0)
    }

    async addAnotherPet (e) {
        e.preventDefault()
        await this.hide()
        this.props.dispatch({
            type: 'UPDATE_NEW_PET', payload: {
                'name': '',
                'weight': '',
                'age': '',
                'body-type': '',
                'activity-level': '',
                'allergies': [],
                'current-diet': []
            }
        })
        document.dispatchEvent(new Event('clear-new-pet'))
        window.location.href = '/meals/#/wizard/name'
    }

    get lineItems () {
        return this.props.products.reduce((acc, { pet, product, prices }) => {
            const index = acc.findIndex(({ petName }) => petName === pet.name)
            if (index === -1) {
                const treats = this.props.treats.reduce((acc, { treat, pet: _pet, type }) => {
                    return _pet.name === pet.name && type === 'plan' ? {
                        qty: acc.qty + treat.qty,
                        price: acc.price + (treat.qty * treat.price)
                    } : acc
                }, { qty: 0, price: 0 })
                const line = {
                    petName: pet.name,
                    planName: prices[0].name,
                    quarts: product.quarts,
                    pints: product.pints,
                    treatsQty: treats.qty,
                    price: this.linePrice(product, prices) + treats.price
                }
                return [...acc, line]
            } else {
                acc[index].quarts += product.quarts
                acc[index].pints += product.pints
                acc[index].price += this.linePrice(product, prices)
            }
            return acc
        }, [])
    }

    get oneTimeTreats () {
        return this.props.treats.reduce((acc, { treat, pet, type }) => {
            if (type === 'one-time') {
                const line = {
                    petName: pet.name,
                    planId: treat.id,
                    planName: treat.name,
                    qty: treat.qty,
                    price: treat.qty * treat.price
                }
                return [...acc, line]
            }
            return acc
        }, [])
    }

    get totalItems () {
        return this.lineItems.length + this.oneTimeTreats.length
    }

    async editName (name) {
        await this.setState({
            editablePet: name,
            newName: name
        })
        document.getElementById(`input_${name}`).focus()
    }

    saveNewName () {
        if (!this.state.newName) {
            document.getElementById(`input_${this.state.editablePet}`).focus()
        }
        const updatedProducts = this.props.products.map(item => {
            if (item.pet.name === this.state.editablePet) item.pet.name = this.state.newName.toUpperCase()
            return item
        })
        const updatedTreats = this.props.treats.map(item => {
            if (item.pet.name === this.state.editablePet) item.pet.name = this.state.newName.toUpperCase()
            return item
        })
        this.props.dispatch({ type: 'UPDATE_CART', payload: updatedProducts })
        this.props.dispatch({ type: 'UPDATE_TREATS', payload: updatedTreats })
        this.setState({
            editablePet: '',
            newName: ''
        })
    }

    get allInfoHeight () {
        let height = 395
        height += this.totalItems * 60
        if (this.state.showCouponInput) height += 76
        if (height > window.innerHeight) height = window.innerHeight
        return (this.state.isExpanded ? height : 0) + 'px'
    }

    renderProducts () {
        return this.lineItems.map(({ petName, planName, quarts, pints, treatsQty, price }) => (
            <tr className="meal-plan" key={petName}>
                <td className="meal-plan_title">
                    {
                        this.state.editablePet === petName ? <Fragment>
                            <input id={`input_${petName}`} name="newName" value={this.state.newName}
                                   onChange={({ target }) => this.setState({ newName: target.value })}
                                   type="text" className="edit-pet-input"/>
                            <button type="button" className="btn btn-link edit" onClick={() => this.saveNewName()}>Save</button>
                        </Fragment> : <Fragment>
                            {petName}’S {planName === 'One time' ? 'ORDER' : 'MEAL PLAN'} <button type="button" onClick={() => this.editName(petName)} className="btn btn-link edit">Edit</button>
                        </Fragment>
                    }
                </td>
                <td className="meal-plan_type">{planName === 'One time' ? 'One-time Purchase' : planName}</td>
                <td className=" meal-plan_quantity">
                    {quarts ? `${quarts} quarts` : ''}
                    {quarts && pints ? ', ' : ''}
                    {pints ? `${pints} pints` : ''}
                    {treatsQty ? `, ${treatsQty} treats` : ''}

                    <button type="button" onClick={() => this.remove(petName)} className="btn btn-link remove">Remove</button>
                </td>
                <td className="meal-plan_price">{formatMoney(price)}</td>
            </tr>
        ))
    }

    renderTreats () {
        return this.oneTimeTreats.map(({ planId, planName, qty, price }) => (
            <tr className="butter-treats_row" key={planId}>
                <td>{planName}
                </td>
                <td className="butter-treats_type">One-time Purchase</td>
                <td className="butter-quantity">
                    <button className="btn btn-link minus-button" onClick={() => this.changeTreatQty(planId, false)}>-</button>
                    <input type="text" name="qty" className="quantity" onChange={(e) => this.changeTreatQty(planId, null, e.target.value)} value={qty}/>
                    <button className="btn btn-link plus-button" onClick={() => this.changeTreatQty(planId, true)}>+</button>
                    <button type="button" onClick={() => this.removeTreat(planId)} className="btn btn-link remove">Remove</button>
                </td>
                <td className="butter-treats_price">{formatMoney(price)}</td>
            </tr>
        ))
    }

    render () {
        const { isExpanded, isMobile } = this.state
        const isHidden = this.props.hideCart //!window.location.href.includes('checkout/meals') && window.location.href.includes('/meals')
        return <main ref={this.cartElement} className={`header-cart ${isExpanded ? 'expanded' : ''} ${isHidden && 'hidden'}`}>
            {/*<div className="checkout-header">*/}
            {/*    <div className="bowl-block">*/}
            {/*        <img src={require(`../../../assets/img/bowl.png`)} alt="bowl"/>*/}
            {/*        <h5>Bowl</h5>*/}
            {/*        <span>3</span>*/}
            {/*    </div>*/}
            {/*    <div className="total-block">$<span>000.00</span></div>*/}
            {/*</div>*/}
            <div className="order-info_top">
                <div className="all-info" style={{ maxHeight: this.allInfoHeight, overflowY: 'auto' }}>
                    <table className="">
                        <thead>
                        <tr>
                            <th>In Your Bowl</th>
                            <th className="bowl-type">Type</th>
                            <th className="bowl-quantity">Quantity</th>
                            <th className="order-info_close-col">
                                <button type="button" onClick={this.hide} className="btn-link order-info_close">
                                    <img src={require('../../assets/img/close-icon.svg')} alt=""/>
                                </button>
                            </th>
                        </tr>
                        </thead>
                        <tbody>

                        {!this.totalItems ? <tr className="meal-plan">
                            <td colSpan={4} className="empty">Your cart is empty</td>
                        </tr> : null}
                        {this.renderProducts()}
                        {this.renderTreats()}
                        </tbody>
                    </table>
                    <div className="shipping-info_block">
                        <div className="shipping-table_block">
                            <table className="shipping-table">
                                <tbody>
                                <Coupon open={this.state.showCouponInput} onToggle={showCouponInput => this.setState({ showCouponInput })}/>

                                <tr>
                                    <td className="f12">Shipping, taxes, and discounts calculated at checkout</td>
                                    <td className="total-price">{formatMoney(this.subTotal)}</td>
                                </tr>
                                </tbody>
                            </table>
                            <div className="checkout-button-container">
                                <a href="/meals/#/checkout" onClick={async e => {
                                    if (!this.lineItems.length && !this.oneTimeTreats.length) e.preventDefault()
                                    await this.hide()
                                }} className={`checkout-link ${(!this.lineItems.length && !this.oneTimeTreats.length) && 'disabled'}`}>REVIEW AND CHECKOUT</a>
                            </div>
                        </div>
                        <a href="/meals/#/checkout/wizard/name" onClick={this.addAnotherPet} className="add-meal">ADD ANOTHER PET’s meal plan</a>
                    </div>
                </div>
                <div className="bowl-count_block" onClick={this.expand}>
                    <p className="bowl-count_block__text">
                        <span>{isMobile ? '50% OFF first subscription order!' : '50% OFF your first subscription order!'}</span>
                        {!isMobile && <a href="/meals">GET STARTED</a>}
                    </p>
                    <div className="bowl-count_block__count">
                        {/*<img src={require('../../assets/img/bowl.svg')} alt=""/>*/}
                        <img src={require('../../assets/img/bowl-01.svg')} alt=""/>
                        <p>Bowl</p>
                        <span className={`bowl-count ${!this.totalItems ? 'empty' : ''}`}>{this.totalItems}</span>
                    </div>
                </div>
            </div>
        </main>
    }
}

const mapStateToProps = (state) => {
    return {
        pet: state.newPet,
        products: state.cart,
        treats: state.treats,
        hideCart: state.hideCart
    }
}

export default connect(mapStateToProps)(Cart)
