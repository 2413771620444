import React from 'react'
import './App.css'

import Cart from './components/cart/Cart'
import { connect } from 'react-redux'

class App extends React.Component {
    componentDidMount () {
        document.addEventListener('storage-updated-checkout', () => {
            const presistedState = JSON.parse(localStorage.getItem('persist:root'))
            if (!presistedState) return
            const payload = Object.keys(presistedState).reduce((acc, key) => {
                return {
                    ...acc,
                    [key]: JSON.parse(presistedState[key])
                }
            }, {})
            this.props.dispatch({ type: 'UPDATE_ALL', payload, trigger: false })
        })
    }

    render () {
        return (
            <Cart/>
        )
    }
}

export default connect()(App)
