import axios from './axios'

const applyCoupon = (coupon) => {
    return axios.post('/validate-coupon', {coupon})
}

export default axios

export {
    applyCoupon
}
