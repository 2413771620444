import { createStore } from 'redux'
import reducer from './reducers'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import expireIn from './transform-expire'

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['cart', 'plan', 'treats', 'coupon', 'newPet', 'user', 'bcProducts', 'quizPassed', 'hideCart', 'shippingAddress', 'order'],
    transforms: [
        expireIn(24 * 60 * 60, 'expire_at')
    ]
}

const persistedReducer = persistReducer(persistConfig, reducer)

export default () => {
    let store = createStore(persistedReducer)
    let persistor = persistStore(store)
    return { store, persistor }
}
