import React, { PureComponent, Fragment } from 'react'
import { applyCoupon } from '../../api'
import { connect } from 'react-redux'
import moment from 'moment'

class Coupon extends PureComponent {
    constructor (props) {
        super(props)
        this.state = {
            // open: false,
            code: '',
            error: ''
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.removeCoupon = this.removeCoupon.bind(this)
    }

    render () {
        if (this.props.coupon) {
            return <tr>
                <td className="f14">Coupon applied: {this.props.coupon.name}</td>
                <td>
                    <span className="plus-icon" onClick={this.removeCoupon}><span>&#10006;</span></span>
                </td>
            </tr>
        }

        const { error, code } = this.state
        const { open } = this.props
        return <Fragment>
            <tr>
                <td className="f14">Apply a promotional code or gift card</td>
                <td>
                    <button className={open ? 'minus-button' : 'plus-button'} onClick={() => this.props.onToggle(!open)} type="button"/>
                </td>
            </tr>
            {open ? <tr className="coupon-tr">
                <td>
                    <input type="text" name="code" value={code} onChange={this.handleChange} className="coupon-input" placeholder="Promo code / gift card number"/>
                    {error ? <p className="error">{error}</p> : ''}
                </td>
                <td>
                    <button onClick={this.handleSubmit} className="checkout-link coupon" disabled={!this.state.code.trim()}>APPLY</button>
                </td>
            </tr> : null}
        </Fragment>
    }

    removeCoupon () {
        this.props.dispatch({ type: 'UPDATE_COUPON', payload: null })
        this.props.onToggle(true)
    }

    handleChange (e) {
        this.setState({ [e.target.name]: e.target.value })
    }

    async handleSubmit () {
        this.setState({ error: '' })
        if (!/\d/.test(this.state.code) && !/[a-zA-Z]/.test(this.state.code)) {
            this.setState({ error: 'This coupon is not valid' })
            return
        }
        try {
            const { data } = await applyCoupon(this.state.code)
            const discount = data.promo ? {
                ...data.promo.coupon,
                ...data.promo,
                id: data.promo.coupon.id,
                promo_id: data.promo.id
            } : data.coupon

            if (
                discount.valid
                && (discount.active === undefined || discount.active)
                && (!discount.max_redemptions || discount.times_redeemed < discount.max_redemptions)
                && (!discount.expires_at || discount.expires_at < Math.floor(+moment() / 1000))
            ) {
                this.props.dispatch({ type: 'UPDATE_COUPON', payload: discount })
            } else {
                this.setState({ error: 'This coupon is not valid' })
            }
        } catch (e) {
            this.setState({ error: e.response ? e.response.data.message : e.message })
        }
    }
}

const mapStateToProps = ({ coupon }) => {
    return { coupon }
}

export default connect(mapStateToProps)(Coupon)
